import axios from "axios";
import { Message } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";
// create an axios instance
const service = axios.create({
  // baseURL: "http://121.32.129.19:8100", // url = base url + request url
  baseURL: window.configData.VUE_APP_BASEURL, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getters.token && config.url.indexOf("dwg/convert/public") == -1) {
      // let each request carry token --['X-Token'] as a custom key.
      // please modify it according to the actual situation.
      // config.headers["access_token"] = getToken(); // old use head
      // for params
      // if (config.params) {
      //   config.params.access_token = getToken();
      // } else {
      //   config.params = {
      //     access_token: getToken()
      //   };
      // }
      config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  error => {
    // do something with request error
    const err = error;
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code.
   */
  response => {
    const res = response.data;
    if (res.code) {
      // if the custom code is not 20000, it is judged as an error.
      if (res.code !== 200) {
        Message({
          message: res.message || "没有操作权限",
          duration: 3 * 1000
        });
        return Promise.reject(res || "error");
      } else {
        return res;
      }
    }
    return res;
  },
  error => {
    const err = error;
    let errData = err.response;
    if (errData && errData.status == 401) {
      store.dispatch("user/resetToken").then(() => {
        location.reload();
      });
      return;
    } else {
      let text = "请联系管理员";
      if (errData && errData.data && errData.data.message) text = errData.data.message;
      text
      // Message({
      //   message: text,
      //   duration: 3 * 1000
      // });
    }
    return Promise.reject(err);
  }
);

export default service;
